import { Component } from "react"


function Footer() {
    return (
        <footer>
            <div className="ct-container">
                <div className="row">
                    <div className="col-md-6 footer-column">
                        <form>
                            <label className="visually-hidden">Interested To Work With Us</label>
                            <div className="d-flex btn-group" role="group">
                                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                <button className="btn btn-primary" type="button">Subscribe</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-2 footer-column">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="footer-title">Company</span>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"> About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Potfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Page</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">FAQ</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Reviews</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 footer-column">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="footer-title">Support</span>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Privacy policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">tram of use</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Buy &amp; Sell</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Reviews</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 footer-column">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="footer-title">Contact &amp; Support</span>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"><i className="fas fa-comments"></i>6th Cross, Madiwala, Bangalore</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="tel:+91 9751573542"><i className="fas fa-phone"></i>+91 9751573542</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:support@clevertack.com"><i className="fas fa-envelope"></i>Email: support@clevertack.com</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="text-center"><i className="fas fa-ellipsis-h"></i></div>
                <div className="row pt-5 mt-5 bottom-footer">
                    <div className="col-md-4 box">
                        <ul className="list-inline quick-links">
                            <li className="list-inline-item">
                                <a href="#">Privacy Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 box">
                        <ul className="list-inline social-buttons">
                            <li className="list-inline-item fb">
                                <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="list-inline-item tw">
                                <a href="#">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item yt">
                                <a href="#">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 box text-right">
                        <span className="copyright quick-links">Copyright © Clevertack
                            <script>document.write(new Date().getFullYear())</script>2022
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;