import { Component } from "react"
import { Row, Col, Navbar, Button } from 'react-bootstrap';
import webicon from '../assets/images/web-design.svg'
import uiicon from '../assets/images/uiux.svg'
import graphic from '../assets/images/graphic.svg'
import project1 from '../assets/images/project1.png'
import workwithus from '../assets/images/workwithus.png'


function OurLatestPro() {
    return (
        <div className="our-latest--project">
            <div className="ct-container text-center">
                <h2 className="mb-3">Our Latest Project</h2>
                <p>To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage But who has any right</p>
                <Row className='py-5 mt-5'>
                    <Col xs lg="4" className='text-left'>
                        <span className="position-relative backshadow"><img src={project1} /></span>
                        <div className='text-left'>
                            <h3 className="mt-3">Web Design</h3>
                            <p className="my-3 f-16">Shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </Col>
                    <Col xs lg="4" className='text-left'>
                        <span className="position-relative backshadow"><img src={project1} /></span>
                        <div className='text-left'>
                            <h3 className="mt-3">Web Design</h3>
                            <p className="my-3 f-16">Shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </Col>
                    <Col xs lg="4" className='text-left'>
                        <span className="position-relative backshadow"><img src={project1} /></span>
                        <div className='text-left'>
                            <h3 className="mt-3">Web Design</h3>
                            <p className="my-3 f-16">Shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default OurLatestPro;