import React from "react";
import Slider from "react-slick";
import TestimonialContent from './testimonial-content';


function Testimonial() {
    const myLists = [
        {
            name: 'CYRUS DAVID',
            profile: require('../assets/images/kesavan.jpg'),
            content: '“Excellent Virtual Production Serives team – from onboarding through day-off execution – Elad and his team were the best we’ve worked with so far with a virtual partner.”'
        },
        {
            name: 'CYRUS DAVID',
            profile: require('../assets/images/kesavan.jpg'),
            content: '“Excellent Virtual Production Serives team – from onboarding through day-off execution – Elad and his team were the best we’ve worked with so far with a virtual partner.”'
        }        ,
        {
            name: 'CYRUS DAVID',
            profile: require('../assets/images/kesavan.jpg'),
            content: '“Excellent Virtual Production Serives team – from onboarding through day-off execution – Elad and his team were the best we’ve worked with so far with a virtual partner.”'
        },
        {
            name: 'CYRUS DAVID',
            profile: require('../assets/images/kesavan.jpg'),
            content: '“Excellent Virtual Production Serives team – from onboarding through day-off execution – Elad and his team were the best we’ve worked with so far with a virtual partner.”'
        }
    ];
    var settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        arrows: false
    };
    return (
        <section className="testimonials">
            <Slider {...settings}>
                {myLists.map((myList, i) => (
                    <div>
                        <TestimonialContent name={myList.name}  profile={myList.profile} content={myList.content} />
                    </div>
                ))
                }
            </Slider>
        </section>
    )
}

export default Testimonial;