import { Component } from "react"
import { Row, Col, Navbar, Button } from 'react-bootstrap';
import webicon from '../assets/images/web-design.svg'
import uiicon from '../assets/images/uiux.svg'
import graphic from '../assets/images/graphic.svg'
import wearehere from '../assets/images/wearehere.png'
import workwithus from '../assets/images/workwithus.png'


function OurServices() {
    return (
        <>
            <div className="ct-container text-center py-5 ourservices">
                <h2>Our Services</h2>
                <p>That are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through</p>
                <Row className='py-5'>
                    <Col xs lg="4" className=''>
                        <span><img src={webicon} /></span>
                        <h3 className="mt-3">Web Design</h3>
                        <p className="mt-3">Shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power.</p>
                        <Button className="mt-3" variant="primary">Learn More</Button>
                    </Col>
                    <Col xs lg="4" >
                        <span><img src={uiicon} /></span>
                        <h3 className="mt-3">UI/UX Design</h3>
                        <p className="mt-3">When nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.</p>
                        <Button className="mt-3" variant="primary">Learn More</Button>
                    </Col>
                    <Col xs lg="4" className=''>
                        <span><img src={graphic} /></span>
                        <h3 className="mt-3">Graphic Design</h3>
                        <p className="mt-3">But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur.</p>
                        <Button className="mt-3" variant="primary">Learn More</Button>
                    </Col>
                </Row>
            </div>
            <div className="bg py-5">
                <div className="ct-container text-center py-5 ">
                    <div className="row g-0">
                        <div className="col-md-7 d-flex justify-content-center flex-column pr-5 text-left">
                            <div className="innercontainer">
                                <h2 className="card-title">We Are Hear To Help You With</h2>
                                <p className="card-text my-3">But I must explain to you how all this mistaken idea of denouncing pleasure
                                    and praising pain was born and I will give you a complete account of the system, and expound
                                    the actual teachings of the great explorer Of the truth, the master-builder of human
                                    happiness. No one rejects, dislikes, or avoids pleasure itself</p>
                                <button type="button" className="btn btn-warning mt-4">Read More</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={wearehere} className="img-fluid rounded-start" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ct-container text-center py-5 ">
                <div className="row g-0">

                    <div className="col-md-5">
                        <img src={workwithus} className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-7 d-flex justify-content-center flex-column pl-5 text-left">
                        <div className="innercontainer pl-5">
                            <h2 className="card-title">Interested To Work With Us?</h2>
                            <p className="card-text my-3">Because it is pleasure, but because those who do not know how to pursue
                                pleasure rationally encounter consequences that are extremely painful. Nor again is there
                                anyone who loves or pursues or desires to obtain pain Of itself, because it is pain, but
                                because occasionally circumstances occur in which toil and pain can procure him some great
                                pleasure</p>
                            <form className="d-flex">
                                <div className="input-group">
                                    <input type="text" className="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" placeholder="Enter your email address" required="" />
                                </div>
                                <button className="btn btn-warning" type="submit">send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurServices;