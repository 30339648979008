import React from "react";


function TestimonialContent({name, profile, content}) {
    return (
        <div className="quote text__dark">
            <div className="quote__image">
                <img src={profile} />
            </div>
            <div className="quote__content">
                <div className="quote__text text__thin text__quote">“Excellent Virtual Production Serives team – from onboarding through day-off execution – Elad and his team were the best we’ve worked with so far with a virtual partner.”
                </div>
                <div className="flex__middle">
                    <strong className="quote__name text__quote">{name}</strong>
                </div>
                <div className="text__quote">Program Manager, University of St. Thomas, Minnesota</div>
            </div>
        </div>
    )
}

export default TestimonialContent;